import { ApolloClient } from '@apollo/client';
import { createSelector, createSlice, PayloadAction, unwrapResult } from '@reduxjs/toolkit';
import { assign, groupBy, isEmpty, isNil, omitBy, uniqBy } from 'lodash';

import { removeAllBuildingHighlights } from 'components/HighlighSets/buildingSelectionStyleHelpers';
import { SearchDataContainer } from 'components/RefreshedUI/SearchDataModels';
import { mapFiltersToCspInputs } from 'components/Search/filterToCspConverters';
import { SearchItemTypes } from 'constants/search.constants';
import { FilterTypes, findMatchedAndUnmatchedOsmIds } from 'helpers/searchHelper';
import { AppDispatch, RootState } from 'store';
import { CspAvailabilityInput } from 'types/cspInputSchemas/CspAvailabilityInputSchema';
import { CspLeaseInput } from 'types/cspInputSchemas/CspLeaseInputSchema';
import { ListOfMarketHierarchyParameterInput } from 'types/cspInputSchemas/CspMarketsHierarchySchema';
import { CspPropertyInput } from 'types/cspInputSchemas/CspPropertyInputSchema';
import { CspSaleInput } from 'types/cspInputSchemas/CspSaleInputSchema';
import { PropertyResultItem } from 'types/Search/PropertySearchResultProps';
import { AvailabilityFilters } from 'types/searchSchemas/AvailabilityFiltersSchema';
import { LeaseFilters } from 'types/searchSchemas/LeaseFiltersSchema';
import { PropertyFilters } from 'types/searchSchemas/PropertyFiltersSchema';
import { SaleFilters } from 'types/searchSchemas/SaleFiltersSchema';
import { PropertyType } from 'types/searchSchemas/SearchFieldSchemas';
import { PROPERTY_CLIENT } from 'utils/gqlUtils';
import { getMarketHierarchy } from 'utils/marketUtils';
import {
    SEARCH_AVAILABILITIES,
    SEARCH_LEASE,
    SEARCH_PROPERTIES_IDS,
    SEARCH_SALES,
} from '../services/graphql/csp';
import { selectMarketSphereOsmMapping } from './marketSphereOsmMappingSlice';
import { clearPresentation, selectSourceSystem, selectSourceSystemType } from './presentationSlice';
import { createAppAsyncThunk } from './typedHelpers';

type ConvertFilterToOutput<T extends SearchItemTypes> = T extends 'properties'
    ? PropertyFilters
    : T extends 'availabilities'
    ? AvailabilityFilters
    : T extends 'leaseComps'
    ? LeaseFilters
    : T extends 'sales'
    ? SaleFilters
    : never;

export interface MarketFilters {
    propertyTypes?: PropertyType[];
    markets?: string[];
    submarkets?: string[];
}

export interface SearchFilters extends Record<SearchItemTypes & 'market', FilterTypes> {
    ['market']?: MarketFilters;
    ['properties']?: PropertyFilters;
    ['availabilities']?: AvailabilityFilters;
    ['sales']?: SaleFilters;
    ['leaseComps']?: LeaseFilters;
}

interface SearchSliceState {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
    availabilityStats?: any;
    isSearchToPresentationModalOpen?: boolean;
    showResults: boolean;
    searchType?: SearchItemTypes;
    missingProperties: PropertyResultItem[];
    selectedResultItem?: PropertyResultItem;
    searchParams: {
        bronzeSourceSystem: string;
        input: string;
    };
    allPropertiesSearchResults: PropertyResultItem[];
    activeFilters: SearchFilters;
    searchData: SearchDataContainer;
    searchDataLoading: boolean;
    showUnmatchedProperties: boolean;
}

const initialState: SearchSliceState = {
    searchType: undefined,
    showResults: false,
    missingProperties: [],
    searchParams: {
        bronzeSourceSystem: '',
        input: '',
    },
    allPropertiesSearchResults: [],

    activeFilters: {
        market: {},
        properties: {},
        availabilities: {
            isAvailable: true,
        },
        sales: {},
        leaseComps: {
            isConfidential: false,
        },
    },

    searchData: {
        type: 'properties',
    },
    searchDataLoading: false,
    showUnmatchedProperties: false,
};

export const searchProperties = createAppAsyncThunk(
    'properties/searchProperties',
    async (
        { client, input }: { client: ApolloClient<object>; input: CspPropertyInput },
        { getState }
    ) => {
        const state = getState();

        const sourceSystem = selectSourceSystem(state);
        const sourceSystemType = selectSourceSystemType(state);

        const result = await client.query({
            query: SEARCH_PROPERTIES_IDS,
            context: { clientName: PROPERTY_CLIENT },
            variables: {
                [sourceSystemType]: sourceSystem,
                input,
            },
        });

        const searchPropertiesResult = result.data.searchProperties;

        return {
            items: searchPropertiesResult.properties,
            stats: searchPropertiesResult.stats,
        };
    }
);

export const searchAvailabilities = createAppAsyncThunk(
    'properties/searchAvailabilities',
    async ({ client, input }: { client: ApolloClient<object>; input: object }, { getState }) => {
        const state = getState();
        const sourceSystem = selectSourceSystem(state);
        const sourceSystemType = selectSourceSystemType(state);
        const result = await client.query({
            query: SEARCH_AVAILABILITIES,
            context: { clientName: 'availabilityServiceEndpoint' },
            variables: {
                [sourceSystemType]: sourceSystem,
                input,
            },
        });

        const searchAvailabilitiesResult = result.data.searchAvailabilities;

        return {
            items: searchAvailabilitiesResult.availabilities,
            stats: searchAvailabilitiesResult.stats,
        };
    }
);

export const searchSales = createAppAsyncThunk(
    'properties/searchSales',
    async ({ client, input }: { client: ApolloClient<object>; input: object }, { getState }) => {
        const state = getState();
        const sourceSystem = selectSourceSystem(state);
        const result = await client.query({
            query: SEARCH_SALES,
            context: { clientName: 'salesServiceEndpoint' },
            variables: {
                silverSourceSystem: sourceSystem,
                input,
            },
        });

        const searchSalesResult = result.data.searchSalesTransaction;
        return { items: searchSalesResult.salesTransactions, stats: searchSalesResult.stats };
    }
);

export const searchLease = createAppAsyncThunk(
    'properties/searchLease',
    async ({ client, input }: { client: ApolloClient<object>; input: object }, { getState }) => {
        const state = getState();
        const sourceSystem = selectSourceSystem(state);
        const result = await client.query({
            query: SEARCH_LEASE,
            context: { clientName: 'leaseServiceEndpoint' },
            variables: {
                silverSourceSystem: sourceSystem,
                input,
            },
        });

        const searchLeaseResult = result.data.searchLeases;
        return { items: searchLeaseResult.leases, stats: searchLeaseResult.stats };
    }
);

const callSearchThunkBySearchType = async (
    searchType: SearchItemTypes,
    client: ApolloClient<object>,
    input: CspAvailabilityInput | CspLeaseInput | CspSaleInput,
    dispatch: AppDispatch
) => {
    let func;
    switch (searchType) {
        case 'availabilities':
            func = searchAvailabilities;
            break;
        case 'leaseComps':
            func = searchLease;
            break;
        case 'sales':
            func = searchSales;
            break;
        default:
            throw new Error(`Unsupported search type: ${searchType}`);
    }

    return await dispatch(func({ client, input: { ...input, pageSize: -1, pageNumber: 1 } }));
};

export const performSearch = createAppAsyncThunk(
    'search/performSearch',
    async ({ client }: { client: ApolloClient<object> }, { getState, dispatch }) => {
        const state = getState();
        const searchType = selectSearchType(state);
        const marketsHierarchy = selectMarketsHierarchy(
            state
        ) as ListOfMarketHierarchyParameterInput;
        const { propertyTypes = [] } = selectMarketFilters(state);
        const filters = selectFiltersBySearchType(state);

        if (!searchType) {
            throw new Error('Search type is not set');
        }

        await removeAllBuildingHighlights('osm');
        await removeAllBuildingHighlights('dev-pipeline');

        const propertySearchInput: CspPropertyInput & { pageSize: number; pageNumber: number } = {
            pageSize: -1,
            pageNumber: 1,
        };

        const searchDataContainer: SearchDataContainer = {
            type: searchType,
        };

        if (searchType === 'properties') {
            const propertyFilters = filters as PropertyFilters;
            const input = mapFiltersToCspInputs(
                propertyFilters,
                searchType,
                marketsHierarchy,
                propertyTypes
            ) as CspPropertyInput;

            assign(propertySearchInput, input, {
                marketsHierarchy,
                propertyTypeSourceValue: propertyTypes,
            });
        } else if (['availabilities', 'leaseComps', 'sales'].includes(searchType)) {
            const input = mapFiltersToCspInputs(
                filters as AvailabilityFilters | LeaseFilters | SaleFilters,
                searchType,
                marketsHierarchy,
                propertyTypes
            ) as CspAvailabilityInput | CspLeaseInput | CspSaleInput;

            const result = await callSearchThunkBySearchType(searchType, client, input, dispatch);

            const unwrappedResult = unwrapResult(result);

            const keyedByPropertyId = groupBy(unwrappedResult.items, 'property.id');

            propertySearchInput.id = Object.keys(keyedByPropertyId);

            searchDataContainer.data = keyedByPropertyId;
        }

        const propertiesResult = await dispatch(
            searchProperties({
                client,
                input: propertySearchInput as CspPropertyInput,
            })
        );

        const unwrappedPropertiesResult = unwrapResult(propertiesResult);

        const properties = unwrappedPropertiesResult.items;
        const geoBoundingBox = unwrappedPropertiesResult.stats.geoBoundingBox;

        return { properties, geoBoundingBox, searchDataContainer };
    }
);

export const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        setMissingProperties(state, action: PayloadAction<PropertyResultItem[]>) {
            const combined = [...state.missingProperties, ...action.payload];
            state.missingProperties = uniqBy(combined, 'id');
        },
        setAvailabilityStats(state, action) {
            state.availabilityStats = action.payload;
        },
        setSearchToPresentationModalOpen(state, action) {
            state.isSearchToPresentationModalOpen = action.payload;
        },
        setSearchType(state, action: PayloadAction<SearchItemTypes>) {
            state.searchType = action.payload;
        },
        setSearchParams(state, action) {
            state.searchParams = action.payload;
            const { bronzeSourceSystem, input } = action.payload;
            state.searchParams.bronzeSourceSystem = bronzeSourceSystem;
            state.searchParams.input = input;
        },
        setShowResults(state, action: PayloadAction<boolean>) {
            state.showResults = action.payload;
        },
        setFilters(
            state,
            action: PayloadAction<{
                filters: FilterTypes;
                type: SearchItemTypes | 'market';
            }>
        ) {
            const { type, filters } = action.payload;

            state.activeFilters[type] = { ...state.activeFilters[type], ...filters };

            const removeEmpty = (obj: object): object => {
                return omitBy(obj, (value) => {
                    if (isNil(value)) return true;
                    if (Array.isArray(value) && isEmpty(value)) return true;
                    if (typeof value === 'object') {
                        const cleaned = removeEmpty(value);
                        return isEmpty(cleaned);
                    }
                    return false;
                });
            };

            state.activeFilters[type] = removeEmpty(state.activeFilters[type]);
        },

        setSelectedResultItem(state, action: PayloadAction<PropertyResultItem | undefined>) {
            state.selectedResultItem = action.payload;
        },

        setDefaultValues(state, action: PayloadAction<{ searchType?: string }>) {
            Object.assign(state, {
                ...initialState,
                searchType: action.payload.searchType,
                missingProperties: state.missingProperties,
            });
        },

        setAllPropertiesSearchResults(state, action: PayloadAction<PropertyResultItem[]>) {
            state.allPropertiesSearchResults = action.payload;
        },

        setSearchDataLoading(state, action: PayloadAction<boolean>) {
            state.searchDataLoading = action.payload;
        },

        setShowUnmatchedProperties(state, action: PayloadAction<boolean>) {
            state.showUnmatchedProperties = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(clearPresentation, () => initialState)
            .addCase(performSearch.pending, (state) => {
                state.searchDataLoading = true;
                state.allPropertiesSearchResults = [];
            })
            .addCase(performSearch.fulfilled, (state, action) => {
                state.searchDataLoading = false;
                state.searchData = action.payload.searchDataContainer;
                state.allPropertiesSearchResults = action.payload.properties;
            })
            .addCase(performSearch.rejected, (state) => {
                state.searchDataLoading = false;
                state.allPropertiesSearchResults = [];
            });
    },
});

export const selectFilters = (state: RootState) => {
    return state.search.activeFilters;
};

export const selectAvailabilitiesFilters = (state: RootState): AvailabilityFilters => {
    return state.search.activeFilters['availabilities'] ?? {};
};

export const selectSalesFilters = (state: RootState): SaleFilters => {
    return state.search.activeFilters['sales'] ?? {};
};

export const selectLeaseFilters = (state: RootState): LeaseFilters => {
    return state.search.activeFilters['leaseComps'] ?? {};
};

export const selectAvailabilityStats = (state: RootState) => {
    return state.search.availabilityStats;
};

export const selectMarketFilters = (state: RootState): MarketFilters => {
    return state.search.activeFilters['market'] ?? {};
};

export const selectPropertyFilters = (state: RootState): PropertyFilters => {
    return state.search.activeFilters['properties'] ?? {};
};

export const selectSearchToPresentationModal = (state: RootState) => {
    return state.search.isSearchToPresentationModalOpen;
};

export const selectSearchType = (state: RootState) => {
    return state.search.searchType;
};

export const selectSearchParams = (state: RootState) => {
    return state.search.searchParams;
};

export const selectShowResults = (state: RootState) => {
    return state.search.showResults;
};

export const isSearchResultsEmpty = (state: RootState) => {
    return state.search.allPropertiesSearchResults?.length > 0;
};

export const selectAllPropertiesSearchResults = (state: RootState) => {
    return state.search.allPropertiesSearchResults;
};

export const selectFiltersBySearchType = createSelector(
    [selectSearchType, (state: RootState) => state.search.activeFilters],
    (searchType, activeFilters): ConvertFilterToOutput<SearchItemTypes> => {
        return (
            (activeFilters[
                searchType as keyof typeof activeFilters
            ] as ConvertFilterToOutput<SearchItemTypes>) || {}
        );
    }
);

export const selectActivePropertyIds = createSelector(
    [selectAllPropertiesSearchResults],
    (properties) => {
        return Array.from(new Set(properties.map((property) => property.id)));
    }
);

export const selectOsmGroupsSearchResults = createSelector(
    [selectAllPropertiesSearchResults, selectMarketSphereOsmMapping],
    (properties, mappingsOsmLookup) => findMatchedAndUnmatchedOsmIds(properties, mappingsOsmLookup)
);

export const selectResultItem = (state: RootState) => {
    return state.search.selectedResultItem;
};

export const selectMissingProperties = (state: RootState) => {
    return state.search.missingProperties;
};

export const selectActiveFilters = (state: RootState) => {
    return state.search.activeFilters;
};

export const selectSearchData = (state: RootState) => {
    return state.search.searchData;
};

export const selectSearchDataLoading = (state: RootState) => {
    return state.search.searchDataLoading;
};

export const selectShowUnmatchedProperties = (state: RootState) => {
    return state.search.showUnmatchedProperties;
};

/**
 * Select the market hierarchy from the current selected markets and submarkets.
 */
export const selectMarketsHierarchy = createSelector(
    [selectMarketFilters],
    (marketFilters): ListOfMarketHierarchyParameterInput | undefined => {
        const { markets = [], submarkets = [] } = marketFilters;

        return getMarketHierarchy(markets, submarkets);
    }
);

export const {
    setMissingProperties,
    setAvailabilityStats,
    setFilters,
    setSearchToPresentationModalOpen,
    setSearchType,
    setSearchParams,
    setShowResults,
    setSelectedResultItem,
    setDefaultValues,
    setAllPropertiesSearchResults,
    setSearchDataLoading,
    setShowUnmatchedProperties,
} = searchSlice.actions;

export default searchSlice.reducer;
