import { Key } from '@jll/react-ui-components';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { includes, keyBy, uniq } from 'lodash';

import { requestKmlRegeneration } from 'api/kmlApi';
import {
    createLibraryLayerTreeItems,
    findItemWithParents,
    getAllLibraryItemsIncludingNested,
    getLibraryItemsByType,
    getLibraryItemsCheckedKeys,
    removeLibraryItemWithKeys,
    replaceLibraryItemByKey,
    searchLibraryItemByKey,
    TreeItemWithParentsResult,
} from 'helpers/libraryLayerHelper';
import {
    allHighlightMetadataForKey,
    buildingMetadataForItemKey,
    getGeometryRefFromNode,
    recreateHighlightSetChildren,
} from 'helpers/osmHighlightSetHelper';
import { AppThunk, RootState } from 'store';
import {
    buildingGeometryRefEqual,
    buildingGeometryRefIdOfType,
} from 'types/Layers/BuildingGeometryRef';
import { CsvLayerMetadata } from 'types/Layers/CsvLayerMetadata';
import LibraryLayerTreeItem, {
    findByKeyOfTypeInTree,
    FolderTreeItem,
    MapItLayerTreeItem,
    MarketViewTreeItem,
    OsmHighlightSetTreeItem,
    primaryLibraryItemKey,
    QuickLayerTreeItem,
} from 'types/Layers/LibraryLayerTreeItem';
import {
    OsmHighlightSetBuilding,
    OsmHighlightSetMetadata,
    treeItemKeyForBuilding,
} from 'types/Layers/OsmHighlightSetMetadata';
import { AnnotationMetadata } from 'types/Layers/QuickLayerMetadata';
import { nanoid } from 'utils/idUtils';
import isDefined from 'utils/isDefined';
import { WebsiteOverlayMetadata } from '../types/Layers/WebsiteOverlayMetadata';
import { confirmMatch, deleteMatch } from './csvHighlightMatchSlice';
import { selectVisibilityRangeLayerId } from './mapRangeSlice';
import { clearPresentation, fetchPresentation } from './presentationSlice';
import { createAppAsyncThunk } from './typedHelpers';

interface LibraryLayerTreeProps {
    libraryItem: LibraryLayerTreeItem;
    index?: number;
}

interface LibraryLayerReplaceProps {
    oldKey: string;
    replace: LibraryLayerTreeItem;
}

interface LayerItemChildProps {
    layerKey: string;
    child: LibraryLayerTreeItem;
    dropIndex: number;
}

interface LibraryItemEditModeProps {
    original: LibraryLayerTreeItem;
    key: string;
    created?: boolean;
}

interface LibraryItemSliceState {
    libraryItems: LibraryLayerTreeItem[];
    checkedKeys: string[];
    loadedKeys: React.Key[];
    expandedKeys: Key[];
    previewTableItem: string | undefined;
    isPreviewTableVisible: boolean;
    libraryItemInEditMode: LibraryItemEditModeProps | null;
}

interface LayerError {
    key: string;
    error: string;
}

const initialState: LibraryItemSliceState = {
    libraryItems: [],
    checkedKeys: [],
    loadedKeys: [],
    expandedKeys: [],
    previewTableItem: '',
    isPreviewTableVisible: false,
    libraryItemInEditMode: null,
};

export const regenerateKmlLayer = createAppAsyncThunk(
    'presentation/regenerateKmlLayer',
    async (layerKey: string, { getState, dispatch }) => {
        const state = getState();
        const libraryItem = searchLibraryItemByKey(state.libraryItems.libraryItems, layerKey);

        if (libraryItem) {
            await requestKmlRegeneration(libraryItem.id);
            dispatch(removeLoadedKeys([libraryItem.key]));
            dispatch(removeLayerError(libraryItem.key));
        }
    }
);

export const libraryItemSlice = createSlice({
    name: 'libraryItems',
    initialState,
    reducers: {
        setLibraryItems: (state, action) => {
            const libraryItems = action.payload as LibraryLayerTreeItem[];
            state.libraryItems = libraryItems;
            state.checkedKeys = getLibraryItemsCheckedKeys(libraryItems as LibraryLayerTreeItem[]);
        },
        addLibraryLayerTreeItem(
            state,
            action: PayloadAction<LibraryLayerTreeItem | LibraryLayerTreeItem[]>
        ) {
            const { payload } = action;
            const libraryItems = Array.isArray(payload) ? payload : [payload];
            state.libraryItems = [...state.libraryItems, ...libraryItems];
        },
        addOrUpdateLayerTreeItem(state, action: PayloadAction<LibraryLayerTreeItem>) {
            const libraryItem = action.payload;
            const itemIndex = state.libraryItems.findIndex((item) => item.key == libraryItem.key);
            if (itemIndex == -1) {
                state.libraryItems.push(libraryItem);
            } else {
                state.libraryItems[itemIndex] = libraryItem;
            }
        },
        addLayerTreeItemAtIndex(state, action: PayloadAction<LibraryLayerTreeProps>) {
            const { libraryItem, index } = action.payload;
            state.libraryItems = [
                ...state.libraryItems.slice(0, index),
                libraryItem,
                ...state.libraryItems.slice(index, state.libraryItems.length),
            ];
        },
        replaceLayerTreeItem(state, action: PayloadAction<LibraryLayerReplaceProps>) {
            const { replace, oldKey } = action.payload;
            replaceLibraryItemByKey(state.libraryItems as LibraryLayerTreeItem[], oldKey, replace);
        },
        removeLayerTreeItemAtIndex(state, action: PayloadAction<number>) {
            state.libraryItems.splice(action.payload, 1);
        },
        removeChildrenFromLayerItem(state, action: PayloadAction<LibraryLayerTreeItem>) {
            const { key } = action.payload as LibraryLayerTreeItem;
            const parentKey = primaryLibraryItemKey(key);
            const parent = state.libraryItems.find((item) => item.key === parentKey);
            if (parent) {
                parent.children = parent.children?.filter((child) => child.key !== key);
            }
        },
        addChildToLayerItemAtIndex(state, action: PayloadAction<LayerItemChildProps>) {
            const { child, layerKey, dropIndex } = action.payload;
            const parent = state.libraryItems.find((item) => item.key === layerKey);
            if (parent) {
                const updatedChildren = parent.children
                    ? [
                          ...parent.children.slice(0, dropIndex),
                          child,
                          ...parent.children.slice(dropIndex),
                      ]
                    : [child];
                Object.assign(parent, {
                    ...parent,
                    children: updatedChildren,
                });
            }
        },
        disableSearchLayers(state, action: PayloadAction<string>) {
            const key = action.payload;
            const layerTypes = [
                'PropertySearch',
                'PropertyView',
                'UnpinnedController',
                'PinnedProperty',
            ];
            state.libraryItems.forEach((libraryItem) => {
                if (includes(layerTypes, libraryItem.itemType) && libraryItem.key !== key) {
                    const nodeIndex = state.checkedKeys.indexOf(libraryItem.key);
                    state.checkedKeys.splice(nodeIndex, 1);
                }
            });
        },
        enableAllLayers(state) {
            const keys: string[] = [];

            for (const libraryItem of state.libraryItems) {
                libraryItem.checked = true;
                keys.push(libraryItem.key);
            }

            state.checkedKeys = keys;
        },
        disableAllLayers(state) {
            for (const libraryItem of state.libraryItems) {
                libraryItem.children?.forEach((child) => (child.checked = false));
                libraryItem.checked = false;
            }
            state.checkedKeys = [];
        },
        updateLibraryItem(
            state,
            { payload }: PayloadAction<{ libraryItem: Partial<LibraryLayerTreeItem>; key: string }>
        ) {
            const { libraryItem, key } = payload;
            const targetItem = searchLibraryItemByKey(
                state.libraryItems as LibraryLayerTreeItem[],
                key
            );

            if (targetItem) {
                Object.assign(targetItem, libraryItem);
            }
        },
        renameLibraryItem(state, { payload }: PayloadAction<{ key: string; title: string }>) {
            renameLibraryItemMut(
                state.libraryItems as LibraryLayerTreeItem[],
                payload.key,
                payload.title
            );
        },
        setLibraryItemsChecked(state, action: PayloadAction<string[]>) {
            action.payload.forEach((key) => {
                if (!state.checkedKeys.includes(key)) state.checkedKeys.push(key);
            });
        },

        setLibraryItemsUnchecked(state, action: PayloadAction<string[]>) {
            const keys = Array.isArray(action.payload) ? action.payload : [action.payload];
            keys.forEach((key) => {
                const itemIndex = state.checkedKeys.indexOf(key);
                if (itemIndex !== -1) state.checkedKeys.splice(itemIndex, 1);
            });
        },
        updateCheckedKeys(state, action: PayloadAction<string[]>) {
            state.checkedKeys = action.payload;
        },

        removeCheckedKeysByPattern(state, action: PayloadAction<string>) {
            const keyPattern = action.payload;
            state.checkedKeys = state.checkedKeys.filter((item) => !item.includes(keyPattern));
        },

        removeCheckedKeys(state, action: PayloadAction<string[]>) {
            const keys = action.payload;
            state.checkedKeys = state.checkedKeys.filter((key) => !keys.includes(key));
        },

        addLoadedKey(state, action: PayloadAction<string>) {
            state.loadedKeys.push(action.payload);
        },
        removeLoadedKeys(state, action: PayloadAction<string | string[]>) {
            let keys = action.payload;
            keys = Array.isArray(keys) ? keys : [keys];
            state.loadedKeys = state.loadedKeys.filter((key) => !keys.includes(key.toString()));
        },
        setExpandedKeys(state, action: PayloadAction<Key[]>) {
            state.expandedKeys = action.payload;
        },
        expandKeys(state, action: PayloadAction<Key[]>) {
            const newKeys = action.payload.filter((key) => !state.expandedKeys.includes(key));
            state.expandedKeys.push(...newKeys);
        },
        collapseKey(state, action: PayloadAction<Key>) {
            state.expandedKeys = state.expandedKeys.filter((key) => key !== action.payload);
        },
        removeLibraryLayerTreeItemByKey(state, action: PayloadAction<string[] | string>) {
            let keys = action.payload;
            keys = Array.isArray(keys) ? keys : [keys];
            state.libraryItems = removeLibraryItemWithKeys(
                state.libraryItems as LibraryLayerTreeItem[],
                keys
            );
        },
        addLayerError(state, action: PayloadAction<LayerError>) {
            const { key, error } = action.payload;
            const layer = state.libraryItems.find((item) => item.key === key);
            if (layer) {
                layer.error = error;
                layer.checked = false;
                layer.disableCheckbox = true;
            }
        },
        updateLibraryItemActiveState(state, action: PayloadAction<string[]>) {
            const allLibraryItems = getAllLibraryItemsIncludingNested(
                state.libraryItems as LibraryLayerTreeItem[]
            );
            allLibraryItems.forEach((layer) => {
                const isActive = action.payload.includes(layer.key);
                layer.active = isActive;
                layer.checked = isActive;
            });
        },
        updateLibraryItemLegendState(state, action: PayloadAction<string[]>) {
            const openKeys = action.payload;
            state.libraryItems.forEach((layer) => {
                layer.children?.forEach((item) => {
                    item.legendEnabled = openKeys.includes(item.key);
                });
                layer.legendEnabled = openKeys.includes(layer.key);
            });
        },
        removeLayerError(state, action: PayloadAction<string>) {
            const key = action.payload;
            const layer = state.libraryItems.find((item) => item.key === key);
            if (layer) {
                layer.error = undefined;
                layer.disableCheckbox = false;
            }
        },
        startEditMode(
            state,
            { payload }: PayloadAction<{ libraryItem: LibraryLayerTreeItem; created?: boolean }>
        ) {
            state.libraryItemInEditMode = {
                original: payload.libraryItem,
                key: payload.libraryItem.key,
                created: payload.created,
            };
        },
        stopEditMode(state) {
            state.libraryItemInEditMode = null;
        },
        moveOsmHighlightPin(
            state,
            {
                payload: { highlightLayerKey, highlightItemKey, position },
            }: PayloadAction<{
                highlightLayerKey: string;
                highlightItemKey: string;
                position: { latitude: number; longitude: number };
            }>
        ) {
            const layer = findByKeyOfTypeInTree(
                state.libraryItems as LibraryLayerTreeItem[],
                'OsmHighlightSet',
                highlightLayerKey
            );
            if (!layer) {
                return;
            }
            const metadata = layer.metadata as OsmHighlightSetMetadata;

            const building = metadata.buildings.find(
                (building) => treeItemKeyForBuilding(layer.key, building) === highlightItemKey
            );

            if (building) {
                building.customPosition = position;
            }
        },
        moveCsvLayerPin(
            state,
            {
                payload: { key, index, position },
            }: PayloadAction<{
                key: string;
                index: number;
                position: { latitude: number; longitude: number };
            }>
        ) {
            const layer = findByKeyOfTypeInTree(
                state.libraryItems as LibraryLayerTreeItem[],
                'CsvLayer',
                key
            );
            if (!layer || !layer.metadata) {
                return;
            }

            layer.metadata.customPositions = {
                ...layer.metadata.customPositions,
                [index]: position,
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(clearPresentation, () => initialState);

        builder
            .addCase(regenerateKmlLayer.pending, (_state) => {
                console.log('regenerateKmlLayer.pending');
            })
            .addCase(regenerateKmlLayer.fulfilled, (_state) => {
                console.log('regenerateKmlLayer.fulfilled');
            })
            .addCase(regenerateKmlLayer.rejected, (_state) => {
                console.log('regenerateKmlLayer.rejected');
            });

        builder
            .addCase(fetchPresentation.pending, () => initialState)
            .addCase(fetchPresentation.fulfilled, (state, { payload: { presentationMarket } }) => {
                const libraryItems = createLibraryLayerTreeItems(presentationMarket.libraryItems);
                state.libraryItems = libraryItems;
                state.checkedKeys = getLibraryItemsCheckedKeys(
                    libraryItems as LibraryLayerTreeItem[]
                );
            });

        function updateHighlightBuildingData(
            state: LibraryItemSliceState,
            itemKey: string,
            thunk: (item: OsmHighlightSetBuilding) => void
        ) {
            const mainOsmLibraryItemKey = primaryLibraryItemKey(itemKey);
            const highlightSetItem = searchLibraryItemByKey(
                state.libraryItems as LibraryLayerTreeItem[],
                mainOsmLibraryItemKey
            );
            if (highlightSetItem?.itemType !== 'OsmHighlightSet' || !highlightSetItem.metadata) {
                console.error(
                    'Tried to update csv key %s inside of library item of incorrect type %o',
                    itemKey,
                    highlightSetItem
                );
                return;
            }

            const item = buildingMetadataForItemKey(itemKey, highlightSetItem.metadata);
            if (!item) {
                console.error('Missing building metadata for %s', itemKey);
                return;
            }

            const existingOsmId = item.geometryRef;

            thunk(item);

            // Reassign the id anytime the osm id changes.
            if (!buildingGeometryRefEqual(item.geometryRef, existingOsmId)) {
                if (item.geometryRef) {
                    item.id = nanoid();
                } else if (isDefined(item.extraId)) {
                    item.id = `unmatched--${item.extraId}`;
                } else {
                    item.id = nanoid();
                }
                state.checkedKeys.filter((checkedKey) => checkedKey !== itemKey);
                state.checkedKeys.push(treeItemKeyForBuilding(highlightSetItem.key, item));
            }

            highlightSetItem.children = recreateHighlightSetChildren(
                highlightSetItem.key,
                highlightSetItem.metadata.buildings,
                highlightSetItem.metadata
            );
        }

        builder.addCase(confirmMatch, (state, { payload: { csvItemKey, osmId } }) => {
            updateHighlightBuildingData(state as LibraryItemSliceState, csvItemKey, (item) => {
                item.geometryRef = { layerType: 'osm', id: osmId };
            });
        });

        builder.addCase(deleteMatch, (state, { payload: { csvItemKey } }) => {
            updateHighlightBuildingData(state as LibraryItemSliceState, csvItemKey, (item) => {
                delete item.geometryRef;
            });
        });
    },
});

function renameLibraryItemMut(libraryItems: LibraryLayerTreeItem[], key: string, title: string) {
    const result = findItemWithParents(libraryItems, key);
    const targetItem = result?.value;

    if (!targetItem) {
        return;
    }

    targetItem.title = title;

    switch (targetItem.itemType) {
        case 'OsmHighlightSet':
            renameOsmHighlightSet(result, title);
            break;
        case 'QuickLayer':
            renameQuickLayer(result, title);
            break;
    }
}

const renameQuickLayer = (itemWithParents: TreeItemWithParentsResult, title: string) => {
    const targetItem = itemWithParents.value as QuickLayerTreeItem;
    const targetMetadata = targetItem.metadata as AnnotationMetadata;

    if (targetItem.itemType === 'QuickLayer' && targetItem.isLeaf) {
        targetMetadata.attributes.title = title;
    }
};

function renameOsmHighlightSet(itemWithParents: TreeItemWithParentsResult, title: string) {
    const targetItem = itemWithParents.value;
    const parent = itemWithParents.parent;

    // We only need to update the custom building name version if the item is a
    // child of a osm highlight set rather than the highlight set itself.
    if (!targetItem.ownerId || parent?.value.itemType !== 'OsmHighlightSet') {
        return;
    }

    const geometryRef = getGeometryRefFromNode(parent.value.metadata, targetItem);

    if (!isDefined(geometryRef)) {
        return;
    }

    const metadata = parent.value.metadata as OsmHighlightSetMetadata;
    const buildingMetadata = metadata.buildings.find((building) =>
        buildingGeometryRefEqual(building.geometryRef, geometryRef)
    );

    if (!buildingMetadata) {
        return;
    }

    buildingMetadata.customName = title;
}

export const replaceWithHighlightSet = (
    highlightLibraryItem: LibraryLayerTreeItem,
    replaceWithItemKey: string
): AppThunk<void> => {
    return (dispatch) => {
        const checkedKeys = highlightLibraryItem.children?.map((item) => item.key) ?? [];
        dispatch(
            replaceLayerTreeItem({ replace: highlightLibraryItem, oldKey: replaceWithItemKey })
        );
        dispatch(removeCheckedKeysByPattern(replaceWithItemKey));
        dispatch(setLibraryItemsChecked(checkedKeys));
    };
};

export const selectCheckedKeys = (state: RootState) => state.libraryItems.checkedKeys;
export const selectCheckedKeyLookup = createSelector(selectCheckedKeys, (checkedKeys) =>
    keyBy(checkedKeys, (key) => key)
);
export const selectParentCheckedKeys = (state: RootState) => {
    const filteredKeys = state.libraryItems.checkedKeys.map(primaryLibraryItemKey);
    return uniq(filteredKeys);
};
export const selectLoadedKeys = (state: RootState) => state.libraryItems.loadedKeys;
export const selectLibraryItems = (state: RootState) => state.libraryItems.libraryItems;
export const selectExpandedKeys = (state: RootState) => state.libraryItems.expandedKeys;

export const selectCheckedLegendEnabledItems = createSelector(
    [selectLibraryItems, selectCheckedKeyLookup],
    (libraryItems, checkedKeyLookup) =>
        libraryItems
            .filter((item) => checkedKeyLookup[item.key])
            .filter((item) => item.legendEnabled)
);

export const selectCheckedLegendEnabledKeys = createSelector(
    [selectCheckedLegendEnabledItems],
    (libraryItems) => libraryItems.map((item) => item.key)
);

export const selectHighlightSetLibraryItems = createSelector([selectLibraryItems], (libraryItems) =>
    getLibraryItemsByType(libraryItems, 'PinnedProperties')
);
export const selectOsmHighlightSetLibraryItems = createSelector(
    [selectLibraryItems],
    (libraryItems) =>
        getLibraryItemsByType(libraryItems, 'OsmHighlightSet').filter(
            (libraryItem) => !libraryItem.ownerId
        )
);

export const selectPreviewTableVisible = createSelector([selectCheckedKeys], (checkedKeys) =>
    checkedKeys.some((item) => item.includes('preview-table'))
);
export const selectQuickLayerLibraryItems = createSelector([selectLibraryItems], (libraryItems) =>
    getLibraryItemsByType(libraryItems, 'QuickLayer').filter((libraryItem) => !libraryItem.ownerId)
);

export const selectActivePreviewTableKey = createSelector([selectCheckedKeys], (checkedKeys) => {
    const previewTableKeys = checkedKeys.filter((item) => item.includes('preview-table'));
    return previewTableKeys[previewTableKeys.length - 1];
});

export const selectItemsInFoldersLookup = createSelector([selectLibraryItems], (libraryItems) => {
    const output: Record<string, FolderTreeItem> = {};
    const folders = libraryItems.filter(
        (libraryItem) => libraryItem.itemType === 'Folder'
    ) as FolderTreeItem[];

    for (const folder of folders) {
        folder.children?.forEach((item) => {
            output[item.key] = folder;
        });
    }

    return output;
});

export const selectWebsiteOverlayLibraryItems = createSelector(
    [selectLibraryItems],
    (libraryItems) => getLibraryItemsByType(libraryItems, 'Overlay')
);

export const selectMapitLibraryItems = createSelector([selectLibraryItems], (libraryItems) =>
    getLibraryItemsByType(libraryItems, 'MapItLayer')
);

export const selectImageOverlayLibraryItems = createSelector([selectLibraryItems], (libraryItems) =>
    getLibraryItemsByType(libraryItems, 'Image')
);

export const selectMarketViewLibraryItems = createSelector([selectLibraryItems], (libraryItems) =>
    getLibraryItemsByType(libraryItems, 'PropertyView')
);

export const selectAmenitiesLibraryItem = createSelector([selectLibraryItems], (libraryItems) =>
    getLibraryItemsByType(libraryItems, 'Amenity').shift()
);

export const selectDevelopmentPipelineLayerItem = createSelector(
    [selectMarketViewLibraryItems],
    (items) => {
        return items.find(
            (item) => (item as MarketViewTreeItem)?.metadata?.fieldName == 'devPipeline'
        );
    }
);

export const isDevelopmentPipelineViewOn = createSelector(
    [selectDevelopmentPipelineLayerItem, selectCheckedKeyLookup],
    (dpView, checkedKeys) => {
        return !!dpView && dpView.key in checkedKeys;
    }
);

export const selectActiveMarketViewItem = createSelector(
    [selectMarketViewLibraryItems, selectCheckedKeyLookup],
    (items, checkedKeys) => {
        return items.find((item) => item.key in checkedKeys);
    }
);

export const selectAllPropertiesLibraryItem = createSelector([selectLibraryItems], (libraryItems) =>
    getLibraryItemsByType(libraryItems, 'UnpinnedController').shift()
);

export const isAllPropertiesChecked = createSelector(
    [selectAllPropertiesLibraryItem, selectCheckedKeys],
    (propertyItem, checkedKeys: string[]) => propertyItem && checkedKeys.includes(propertyItem.key)
);

export const selectLibraryItemInEditMode = (state: RootState) => {
    return state.libraryItems.libraryItemInEditMode;
};

export const selectLibraryItemInVisibilityRange = createSelector(
    [selectMapitLibraryItems, selectVisibilityRangeLayerId],
    (libraryItems: MapItLayerTreeItem[], layerId): MapItLayerTreeItem | undefined => {
        if (!layerId) return;
        return searchLibraryItemByKey(libraryItems, layerId) as MapItLayerTreeItem;
    }
);

export const selectWebsiteOverlaysInEditMode = createSelector(
    [selectLibraryItems],
    (libraryItems) =>
        getLibraryItemsByType(libraryItems, 'Overlay').find(
            (item) => (item.metadata as WebsiteOverlayMetadata).isEditing
        )
);

export const selectOriginalHighlightSetInEditMode = (state: RootState) => {
    const itemInEditMode = state.libraryItems.libraryItemInEditMode;
    return itemInEditMode && itemInEditMode.original.itemType === 'OsmHighlightSet'
        ? itemInEditMode.original
        : undefined;
};

export const selectHighlightSetInEditMode = (state: RootState) => {
    const itemInEditMode = state.libraryItems.libraryItemInEditMode;
    if (itemInEditMode == null || itemInEditMode.original.itemType !== 'OsmHighlightSet') {
        return undefined;
    }
    const item = searchLibraryItemByKey(
        state.libraryItems.libraryItems as LibraryLayerTreeItem[],
        itemInEditMode.key
    );
    if (item?.itemType == 'OsmHighlightSet') return item;
};

export const selectLibraryItemsByKey = createSelector(
    (state: RootState) => state.libraryItems.libraryItems,
    (libraryItems) => {
        const result: Record<string, LibraryLayerTreeItem | undefined> = {};
        // Create a copy so we can mutate this copy as the stack
        const stack = [...libraryItems];
        let currentItem: LibraryLayerTreeItem | undefined;
        while ((currentItem = stack.pop())) {
            result[currentItem.key] = currentItem;
            if (currentItem.children) {
                stack.push(...currentItem.children);
            }
        }
        return result;
    }
);

export interface OsmLookupItem {
    key: string;
    checked: boolean;
    libraryItem: OsmHighlightSetTreeItem;
    csvLayerMetadata: Omit<CsvLayerMetadata, 'styles'> | undefined;
    building: OsmHighlightSetBuilding;
}

export function osmLookupItemForBuilding(
    libraryItem: OsmHighlightSetTreeItem,
    building: OsmHighlightSetBuilding,
    checked: boolean
): OsmLookupItem {
    return {
        key: treeItemKeyForBuilding(libraryItem.key, building),
        checked: checked,
        libraryItem,
        csvLayerMetadata: libraryItem.metadata?.csvLayerMetadata,
        building: building,
    };
}

export function osmLookupItemForKey(
    primaryLibraryItem: LibraryLayerTreeItem,
    buildingItemKey: string,
    checked: boolean
): OsmLookupItem | undefined {
    if (primaryLibraryItem.itemType !== 'OsmHighlightSet') {
        return undefined;
    }

    const { buildingMetadata } = allHighlightMetadataForKey(buildingItemKey, primaryLibraryItem);

    if (!buildingMetadata) {
        return undefined;
    }

    return osmLookupItemForBuilding(primaryLibraryItem, buildingMetadata, checked);
}

export const selectOsmHighlightSetLookup = createSelector(
    [selectOsmHighlightSetLibraryItems, selectCheckedKeyLookup],
    (highlightSetLayerItems, checkedKeyLookup) => {
        const lookup: Record<string, OsmLookupItem[] | undefined> = {};

        for (const libraryItem of highlightSetLayerItems) {
            libraryItem.metadata?.buildings.forEach((building) => {
                const osmId = buildingGeometryRefIdOfType(building.geometryRef, 'osm');
                if (!osmId) {
                    return;
                }

                if (!lookup[osmId]) {
                    lookup[osmId] = [];
                }

                lookup[osmId].push(
                    osmLookupItemForBuilding(
                        libraryItem,
                        building,
                        !!checkedKeyLookup[treeItemKeyForBuilding(libraryItem.key, building)]
                    )
                );
            });
        }

        return lookup;
    }
);

export const {
    addLibraryLayerTreeItem,
    addOrUpdateLayerTreeItem,
    addLayerTreeItemAtIndex,
    replaceLayerTreeItem,
    removeLayerTreeItemAtIndex,
    addChildToLayerItemAtIndex,
    updateLibraryItem,
    setLibraryItemsChecked,
    setLibraryItemsUnchecked,
    updateCheckedKeys,
    removeCheckedKeys,
    removeCheckedKeysByPattern,
    addLoadedKey,
    removeLoadedKeys,
    addLayerError,
    removeLayerError,
    removeLibraryLayerTreeItemByKey,
    setExpandedKeys,
    expandKeys,
    collapseKey,
    removeChildrenFromLayerItem,
    updateLibraryItemActiveState,
    updateLibraryItemLegendState,
    enableAllLayers,
    disableAllLayers,
    startEditMode,
    stopEditMode,
    moveOsmHighlightPin,
    moveCsvLayerPin,
    renameLibraryItem,
} = libraryItemSlice.actions;

export default libraryItemSlice.reducer;
